import schttp from 'public/src/services/schttp'

/**
 * 请求apollo获取站点是否支持销量标签配置
 */
export default async function getApolloConfig() {
  const res = await schttp({
    method: 'POST',
    url: '/api/config/apolloConfig/query',
    data: { apolloKeys: 'shein_suggested_sale_price' }
  })
  const { shein_suggested_sale_price = {} } = res?.info || {}
  const { SiteUID } = gbCommonInfo
  const apolloConfig = {
    isSuggestedSalePrice: !!shein_suggested_sale_price[SiteUID]
  }
  return apolloConfig
}
