import schttp from 'public/src/services/schttp'
import middleware from '../middleware'
import { getCookie } from '@shein/common-function'
class OwnRecommend {
  async fetchData(opts = {}) {
    let { url, type = 'GET', data = {}, timeout = 10000, pageNum = 1, limit = 40 } = opts
    const queryOpts = {
      url,
      method: type,
      timeout
    }
    const params = {
      ...{
        pageNum,
        limit, // 分页用 一页请求的数量
      },
      ...data,
    }
    try {
      const isDebugger = getCookie('debugger') 
      if (isDebugger) {
        if (url.indexOf('?') == -1) {
          url = `${url}?debugger=1`
        } else {
          const [_url, p] = url.split('?')
          url = `${_url}?debugger=1&${p}`
        }
      }
    } catch (error) {
      console.log(error)
    }
    if (type == 'GET') {
      queryOpts.params = params
    } else {
      queryOpts.data = params
    }
    let results = await schttp(queryOpts)
    let list = []
    let responseInfo = {}
    if (results.code == 0 && results.info) {
      // 接口数据兼容
      if (results.info.products instanceof Array) list = results.info.products
      if (results.info.list instanceof Array) list = results.info.list
      if (results.info.goods instanceof Array) {
        list = middleware.adapter({ type: 'own', data: results.info.goods })
      }
      // /api/cart/getSaveForLater/get 接口兼容
      if (results.info.wishlist instanceof Array) {
        list = results.info.wishlist.map((item) => {
          item.goodsId.attrValueId = item.attrValueId
          item.goodsId.sku_code = item.sku_code
          return item.goodsId
        })
      }
      responseInfo = results
    } else {
      return Promise.reject(new Error(`url: ${url}: ${results.code}, ${results.msg}`))
    }

    return Promise.resolve({
      products: list,
      dataType: 'own',
      isFaultTolerant: 0,
      responseInfo: responseInfo
    })
  }
}

export default new OwnRecommend()
