import { debounce } from '@shein/common-function'
import expose from 'public/src/services/goodsExpose'
import { daEventCenter } from 'public/src/services/eventCenter/index'

class analysis {
  constructor ({
    recommendType = 'swiper',
    layout = '', 
    listType = 'recommend-product-list', 
    scrollContainer = window,
    getExposeElements = null, // 外界调用，知道当前曝光的元素做其他业务处理
    scrollBottomMask = 0,
    exposeDaEventId = '2-3-2'
  }) {
    this.layout = layout
    this.listType = listType
    this.scrollContainer = scrollContainer
    this.scrollBottomMask = scrollBottomMask
    this.expose = null
    this.exposeDaEventId = exposeDaEventId
    this.getExposeElements = getExposeElements

    switch (recommendType) {
      // 针对swiper的曝光操作
      case 'swiper':
        this.container = `${this.layout}-${this.listType}`
        this.initSwiperScrollEvent()
        this.preExposeProductList = [] // goodsInfo
        this.hasExposeProductList = [] // goodsId
        break
      case 'list':
        this.expose = new expose()
        this.listGoodsExpose()
        break
    }
  }
  // 针对swiper的曝光操作
  initSwiperScrollEvent () {
    this.scrollContainer.addEventListener('scroll', 
      debounce({
        func: e => {
          let el = document.querySelector(`.${this.container}`)
          if (el) {
            const elStyle = window.getComputedStyle(el)
            if (elStyle.getPropertyValue('visibility') == 'hidden' || el.getBoundingClientRect().height == 0) return
            if (el && el.getBoundingClientRect().top + el.offsetHeight * 0.6 < window.innerHeight && el.getBoundingClientRect().top + el.offsetHeight * 0.6 > 0) {
              this.swiperGoodsExpose()
            }
          }
        },
        wait: 800
      }))
  }
  
  // 针对swiper的曝光操作
  swiperGoodsExpose () {
    if (!this.preExposeProductList || this.preExposeProductList.length <= 0) return
    let targes = [];
    let exposeContent = []
    this.preExposeProductList.forEach((item) => {
      if (this.hasExposeProductList.includes(item.goods_id)) return
      let target = document.querySelector(`.${this.container}`)?.querySelectorAll(`.j-expose__product-item-img[data-id="${item.goods_id}"]`)
      if (target.length) {
        this.hasExposeProductList.push(item.goods_id)
        targes.push(target[0])
        exposeContent.push({
          content: target[0]
        })
      }
    })
    if (typeof this.getExposeElements == 'function') this.getExposeElements(exposeContent)

    daEventCenter && daEventCenter.triggerNotice({
      daId: this.exposeDaEventId,
      target: targes
    })

    this.preExposeProductList = []
  }

  // 针对list的曝光操作
  listGoodsExpose () {
    if (!this.expose) return
    this.expose.initInstance({
      config: {
        scrollContainer: this.scrollContainer,
        scrollBottomMask: this.scrollBottomMask,
        section: [
          {
            code: this.listType,
            container: this.listType,
            content: 'content-goodsls',
            target: 'product-item-img',
            averageCotent: false
          }
        ]
      },
      getExposeData: ({list}) => {
        if (!list[this.listType] || list[this.listType].length <= 0) return
        if (typeof this.getExposeElements == 'function') this.getExposeElements(list[this.listType])
        
        let targets = []
        list[this.listType].forEach(({target}) => {
          targets.push(target)
        })

        daEventCenter.triggerNotice({
          daId: this.exposeDaEventId,
          target: targets
        })
      }
    })
  }

  adjustContainerInfo () {
    this.expose && this.expose.adjustContainerInfo()
  }

  triggerScroll () {
    this.expose && this.expose.triggerScroll()
  }

  refreshHasBeenExpose () {
    this.expose && this.expose.refreshHasBeenExpose()
  }
}

export default analysis