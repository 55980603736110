<template>
  <div
    class="product-list__holder"
    :class="`product-list__holder-${type}`"
  >
    <div
      v-for="(i, index) in Math.ceil(num)"
      :key="i"
      class="product-list__item-wrapper"
      :style="{
        ...wrapperStyle,
        ...getMargin(index),
        ...extWrapperStyle,
      }"
    >
      <ProductItemHolder 
        :hide-field="hideField"
        :brand="brand"
        :hide-padding="hideItemPadding(index)"
      />
    </div>
  </div>
</template>

<script>
import ProductItemHolder from './ProductItemHolder.vue'
const { GB_cssRight } = gbCommonInfo
export default {
  name: 'CCCProductListHolder',
  components: {
    ProductItemHolder,
  },
  props: {
    /**
     * 列表的类型
     * @param {*} vertical 纵向
     * @param {*} horizontal 横滑
     */
    type: {
      type: String,
      default: 'horizontal',
    },
    // 占位数量
    num: {
      type: Number,
      default: 4,
    },
    // 一行几个商品
    column: {
      type: Number,
      default: 6,
    },
    brand: {
      type: String,
      default: 'shein',
    },
    extWrapperStyle: {
      type: Object,
      default: () => ({}),
    },
    hidePadding: {
      type: Boolean,
      default: false,
    },
    hideField: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    wrapperStyle() {
      const gapNum = this.column - 1
      const gapCount = gapNum * 0.01 //
      return {
        width: `${Number((1 - gapCount) / this.column).toFixed(5) * 100}%`,
        marginRight: GB_cssRight ? 0 : '1%',
        marginLeft: GB_cssRight ? '1%' : 0,
      }
    },
    // 纵向列表最后一行隐藏item的底部空间
    hideItemPadding() {
      return (i) => {
        if(this.hidePadding) return true
        if (this.type != 'vertical') return false
        // 是否是最后一行商品
        const isLastLine = i + 1 + this.column > this.num
        return isLastLine
      }
    }
  },
  methods: {
    getMargin(i) {
      if (this.type != 'vertical') return {}
      if (String((i + 1) / parseInt(this.column)).indexOf('.') === -1) { 
        return GB_cssRight ? { marginLeft: 0 } : { marginRight: 0 }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.product-list {
  &__holder {
    width: 100%;
    overflow: hidden;
  }

  &__holder-horizontal {
    white-space: nowrap;
  }

  &__item-wrapper {
    display: inline-block;
  }
}
</style>
