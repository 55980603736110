<template>
  <section
    :data-scarabitem="productItem.goods_id"
    class="j-recommend-product-wrap j-expose__content-goodsls"
    :data-expose-id="getExposeId(productItemIndex, productItem.goods_id)"
  >
    <component
      :is="config.isNew ? 'ProductItemV3' : 's-product-item'"
      ref="goodsItem"
      :key="productItem.goods_id"
      :class="{
        's-product-item-has-hover': config.useHoverEffect
      }"
      :item="productItem"
      :index="Number(productItemIndex)"
      :config="config"
      :product-design-width="productDesignWidth"
      :loading="status.loading"
      :goods-item-info="goodsItemInfo"
      :language="language"
      :constant-data="constantData"
      :bottom-info-style="bottomInfoStyle"
      @add-wish-succ="handleAddWishSucc"
      @add-wish-analysis="handleAddWishAnalysis"
      @click-item="handleClickItem"
      @click-add-to-bag="openQuickView"
      @open-quick-add="openQuickView"
      @add-bag-before-request="handleAddTobagBtn"
    />
  </section>
</template>

<script>
import SProductItem from 'public/src/pages/components/productItem/ProductItem.vue'
import ProductItemV3 from 'public/src/pages/components/productItemV3/MultipleImageRowCard.vue'
let QuickViewPopover = null

export default {
  components: {
    SProductItem,
    ProductItemV3,
  },
  props: {
    productItem: {
      type: Object,
      default() {
        return {}
      }
    },
    productItemIndex: {
      type: Number,
      default: 0
    },
    /**
     * item 项的配置
     */
    config: {
      type: Object,
      default() {
        return {}
      }
    },
    exposeInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    goodsItemInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    constantData: {
      type: Object,
      default() {
        return {}
      }
    },
    titleConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    productDesignWidth: {
      type: [String, Number],
      default: 0,
    },
    bottomInfoStyle: {
      type: Object,
      default: () => ({ minHeight: '98px' }),
    },
  },
  data() {
    return {
      status: {
        loading: false
      }
    }
  },
  methods: {
    getExposeId (index, goodsId) {
      let exposeId = `${index}-${goodsId}`
      if (this.exposeInfo && this.exposeInfo.listType) exposeId = `${exposeId}-${this.exposeInfo.listType}`
      return exposeId
    },
    async handleClickItem(payload) {
      const { goods_id, index, target, attrValueId, sku_code = '', mall_code = '', imgRatio = '3-4' } = payload
      // 1. 打开快速加车
      if (this.config.disableMainimgJump && typeof this.config.handleItemClick == 'function') {
        this.status.loading = true
        this.config.handleItemClick({ goods_id, index, target, imgRatio, moduleName: 'recommend', attrValueId, sku_code, mall_code, ...payload }, this.$refs.goodsItem)
        setTimeout(() => {
          this.status.loading = false
        }, 1000)
        return
      }
    },
    handleAddWishSucc () {
      this.$emit('addWishSucc')
    },
    handleAddWishAnalysis(payload) {
      this.$emit('addWishAnalysis', payload)
    },
    openQuickView ({ goodsItem, target, imgRatio }) {
      this.status.loading = true
      const activityFrom = this.analysisInfo?.activeFrom || this.titleConfig?.enValue?.toLocaleLowerCase().split(' ').join('_') || ''
      const cartHideEstimatedPrice = goodsItem?.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice

      const config = !cartHideEstimatedPrice && this.config?.showEstimatedPriceOnSale ? {
        // 显示到手价
        showEstimatedPrice: true,
      } : {}

      const quickViewData = {
        goodsId: goodsItem.goods_id,
        mallCode: goodsItem.mall_code,
        imgRatio,
        // lockmall: true,
        config,
        analysis: {
          sourceTarget: target,
          sa: {
            code: this.config.code,
            activity_from: activityFrom,
          },
          ga: {
            eventCategory: '推荐列表'
          },
          extendAnalysis: {
            isNew: goodsItem?.pretreatInfo?.badgeInfo?.isNewTag ? 1 : 0,
          }
        },
        callback: {
          handleAddToCartDone: (res) => {
            if (typeof this.config.handleClickAddBag == 'function' ) this.config.handleClickAddBag()
            // 快捷购物车刷新购物车清单
            if (res.code == 0) {
              window.cart_module ? window.cart_module.recart(res) : window.location.reload()
            }
          },
          handleOpenLogin: (qvAddWishFn) => {
            SHEIN_LOGIN?.show?.({
              from: 'recommendList',
              cb: () => typeof qvAddWishFn == 'function' && qvAddWishFn()
            })
          }
        }
      }

      if (QuickViewPopover) {
        QuickViewPopover.show(quickViewData)
        this.status.loading = false
      } else {
        import('public/src/pages/common/quickView').then(_ => {
          QuickViewPopover = _.default
          QuickViewPopover.show(quickViewData)
          this.status.loading = false
        })
      }

    },
    handleAddTobagBtn (val) {
      this.$emit('handleAddTobagBtn', val)
    }
  },
  emits: ['addWishSucc', 'handleAddTobagBtn', 'addWishAnalysis'],
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.recommend-goodsd {
  // common Start
  position: relative;
  .txt-l();
  // img{
	// 	width: 100%;
	// }
  .recommend-title {
		margin: 60px 0 20px;
		> label {
      display: inline-block;
			position: relative;
			font-size: 24px;
      color: #222;
      font-weight: 700;
      text-transform: capitalize;
    }

    &__main {
      position: relative;
			font-size: 24px;
      color: #222;
      font-weight: 700;
      text-transform: capitalize;
      /* rw:begin */
      font-family: Adieu;
      /* rw:end */
    }

    &__sub {
      margin-top: 5px;
      font-size: 14px;
      line-height: 16px;
      color: #666;
      font-weight: 400;
    }

		&_show-bottom-line {
      border-bottom: 1px solid #ccc;
    }
	}
  .module-center-h {
		margin-bottom: 40px;
    text-transform: capitalize;
    text-align: center;
    /* rw:begin */
    font-family: Adieu;
    /* rw:end */
	}
  .view-more-a {
    padding-top: 132%;
    position: relative;
    display: block;
    background: #f6f6f6;
    .more-item {
      position: absolute;
      top: 45%;
      width: 100%;
      text-align: center;
      font-size: 18px;
      text-transform: capitalize;
      text-decoration: underline;
    }
  }
  .swiper-container {
    .goodsli-series-badge {
      width: 32px;
    }
    &__tile {
      margin-left: auto/*rtl:ignore*/;
      margin-right: auto/*rtl:ignore*/;
    }

    // &__normal {
    //   margin: 0 5%/*rtl:ignore*/;
    // }

    &__slide-item {
      width: 20%;
    }
	}
  .recommend-con {
		position: relative;
  }
  // common End

  // item Start
  .goodsli-creator-wrapper {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    opacity: .8;
    .goodsli-creator {
      width: 80%;
      height: auto;
    }
  }
  .goodsli-series-badge {
    position: absolute;
    top: 10px;
    .right(10px);
    width: 56px;
    height: auto;
  }
  .goodsli-tag {
    position: absolute;
    bottom: 0;
    left: 0/*rtl:ignore*/;
    right: 0/*rtl:ignore*/;
    min-height: 25px;
    padding: 6px 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.85);
    transition: background-color .3s;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
    &.goodsli-tag-second {
      bottom: 25px;
    }
    &.goodsli-tag-has-fs {
      bottom: 46px;
    }
  }
  .goodsli-fs {
    position: absolute;
    bottom: 0;
    left: 0/*rtl:ignore*/;
    right: 0/*rtl:ignore*/;
    height: 46px;
    padding: 0 5px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #222;
    .fs-progress-ctn {
      display: inline-block;
      height: 46px;
      vertical-align: top;
      width: 100%;
      .padding-l(5px)/*rtl:ignore*/;
      .padding-r(5px)/*rtl:ignore*/;
      padding-top: 10px;
      color: #fff;
      text-transform: lowercase;
      .fs-progress {
        position: relative;
        background-color: #f6f6f6;
        width: 100%;
        height: 8px;
        margin-bottom: 5px;
        border: 1px solid #ccc;
        .fs-progress-solid {
          height: 6px;
          width: 30%;
          background: #FCD364 /*rw:#F99*/;
        }
      }
      .fs-countdown {
        .fr();
      }
    }
  }
  .recommend-img{
    position: relative;
    .rational-box(133.18%);
    display: block;
    .add-loading{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: fade(#222,30%);
      >div{
        position: absolute;
          top: 50%;
          left: 50%/*rtl:ignore*/;
          transform: translate(-50%,-50%)/*rtl:ignore*/;
          >div{
            background-color: fade(#fff,50%);
            width: 12px;
            height: 12px;
            margin: 0 3px;
          }
      }
    }
    .more-discount {
      background: #d53333;
      height: auto;
      width: 40px;
      line-height: 20px;
      /*rw:begin*/
      background: #FF696E;
      color: #fff;
      span {
        display: block;
      }
      .origin-discount {
        text-decoration: line-through;
        opacity: .8;
      }
    }
  }
  .recommend-name {

    &__container {
      position: relative;
    }

    &__has-icon {
      .padding-r(20px);
    }

    color: #767676;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .recommend-add-btn {
    position: absolute;
    top: -4px;
    .right(0);
    cursor: pointer;
  }
  .recommend-size-box {
    .size-container{
      position: absolute;
      top: 25px;
      .right(-25px);
      max-width: 152px;
      background: #fff;
      box-shadow: 0 0 4px #ddd;
      padding: 5px;
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      &::before{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        box-shadow: -2px -2px 4px #ddd;
        top: -5px;
        transform: rotate(45deg) translate(50%,-50%);
        background: #fff;
        .right(35px);
      }
    }
    .size-item{
      cursor: pointer;
      display: inline-block;
      border: 1px solid;
      border-radius: 9999px;
      border-color: #fff;
      margin: 5px;
      line-height: 30px;
      height: 30px;
      text-align: center;
      box-sizing: border-box;
      transition: all 0.2s;
      padding: 0 10px;
      min-width: 30px;
      color: #222;
      &:hover{
        border-color: #222;
      }
      &_out-stock {
        color: #aaa;
        border-style: dashed;
        &:hover {
          border-color: #aaa;
        }
      }
    }
  }
  .recommend-price{
    margin: 20px 0 8px;
    color: #222;
    font-weight: normal;
    text-align: center;
    .sale{
      color: #222;
    }
  }
  .recommend-price-left{
    margin: 0;
    .txt-l();
    font-weight: 700;
  }
  .recommend-price-red{
    font-weight: bold;
    color: #d53333;
    /*rw:begin*/
    color: @color_brand;
    .sale{
      color: #222;
    }
  }
  .btm-related-color {
    padding-bottom: 50px;
    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      .margin-r(5px);
      &.can-change-color {
        .margin-r(0);
        border: 1px solid #fff;
      }
    }
    a {
      display: inline-block;
      .margin-r(5px);
      border: 1px solid #fff;
      text-decoration: none;
      &:hover {
        border: 1px solid #222;
        cursor: pointer;
      }
      &.color-active {
        border: 1px solid #222;
      }
    }
  }
  .multi-color-omit {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 22px;
    width: 22px;
    text-align: center;
    vertical-align: middle;
    &>span, &>i {
      vertical-align: middle;
      display: inline-block;
      height: 22px;
      width: 22px;
      line-height: 22px;
      text-align: center;
    }
    &>i:before {
      width: 21px;
      height: 21px;
      display: inline-block;
    }
    &:hover {
      > span, > i {
        border: 1px solid #222;
      }
    }
    .multi-color-omit-ctn {
      display: none;
      position: absolute;
      left: 50%/*rtl:ignore*/;
      transform: translateX(-50%)/*rtl:ignore*/;
      top: 22px;
      background: white;
      padding: 5px;
      z-index: @zindex-hover-tip;
      border: 1px solid #222;
      cursor: default;
      white-space: nowrap;
      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border: 1px solid #222;
        position: absolute;
        left: 50%/*rtl:ignore*/;
        transform: translateX(-50%) rotate(45deg)/*rtl:ignore*/;
        top: -5px;
        border-right: none;
        border-bottom: none;
        background: white;
      }
      >a:last-child {
        .margin-r(0);
      }
    }
  }
  .recommend-o-price{
    margin: 5px 0 0 0;
  }
  .btm-wrap {
    position: absolute;
    top: 0;
    .right(1px);
    .btm-icon {
      .btm-icon-save {
        width: 16px;
        height: 18px;
        cursor: pointer;
        z-index: @zindex-hover-tip;
        /*rw:begin*/
        color: #999;
        &:hover {
          /*rw:begin*/
          color: @color_brand;
          .btm-save-tip {
            color: #222;
          }
        }
      }
      .btm-icon-saved {
        /*rw:begin*/
        color: @color_brand;
        &:before {
          /*sh:begin*/
          content: "\e64c"; /* stylelint-disable-line declaration-property-value-blacklist */
          /*rw:begin*/
          content: "\e663"; /* stylelint-disable-line declaration-property-value-blacklist */
        }
      }
      .btm-save-tip {
        position: absolute;
        top: 24px;
        background-color: #fff;
        white-space: nowrap;
        z-index: @zindex-hover-tip;
        color: #222;
        /*sh:begin*/
          border-radius: 2px;
          font-size: 12px;
          padding: 10px 15px;
          box-shadow: 0 2px 5px fade(#000, 8%);
          min-width: 116px;
          min-height: 35px;
          text-align: center;
          border: 1px solid #F2F2F2;
          line-height: 1.1;
        /*rw:begin*/
          border-radius: 3px;
          border: 1px solid #ccc;
          height: 23px;
          line-height: 15px;
          padding: 4px 8px;
        &:before {
          content: '';
          position: absolute;
          top: -5px;
          width: 8px;
          height: 8px;
          background: #fff;
          transform: rotate(135deg);
          /*rw:begin*/
            border-left: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
          /*sh:begin*/
            border-left: 1px solid #F2F2F2;
            border-bottom: 1px solid #F2F2F2;
        }
      }
      .btm-save-tip {
        .left(auto);
        .right(-3px);
        &:before {
          .left(auto);
          .right(6px);
        }
      }
    }
    .saved-animate {
      position: absolute;
      display: inline-block;
      box-sizing: content-box;
      padding-top: 6px;
      padding-bottom: 6px;
      /*rw:begin*/
        width: 38px;
        height: 38px;
        left: -10px;
        top: -10px;
      /*sh:begin*/
        width: 19px;
        height: 19px;
        left: -2px;
        top: 0;
    }
  }
  .recommend-bottm {
    position: relative;
    padding-top: 10px;

    .recommend-tb-price {
      position: relative;
      margin-bottom: 5px;
      &__has-icon {
        .padding-r(20px);
      }
      .recommend-price{
        display: inline-block;
        .margin-r(5px);
        font-size: 16px;
      }

      .recommend-o-price {
        display: inline-block;
        font-size: 14px;
        color: #999;
        margin: 0;
      }
    }
  }
  .recommend-rank{
    .txt-l();
    margin-bottom: 5px;
    .c-star{
      font-size:0;
      .margin-r(5px);
    }
  }
  .reducePrice_tips {
    color: #FF411C;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
  }
  // item End
}

.new-cart-recommend-list {
  min-width: 800px;
  max-width: 1370px;
}

@media (min-width: 1366px) {
  .new-cart-recommend-list {
    padding-left: 45px;
    padding-right: 45px;
    max-width: 1440px;
  }
}

.cart-recommend-list .recommend-goodsd .recommend-title {
  border: none;
  margin: 0;
  /* rw:begin */
  font-family: Adieu;
  /* rw:end */
}
.s-product-item-has-hover {
  /* stylelint-disable-next-line */
  padding: 8px !important;
}
</style>
