import { FetchData } from './fetchData'
import analysis from './analysis'
import middleware from './middleware'
import { logicMap } from './emarsys/config'
import emarsys from 'public/src/services/productRecommend/emarsys/index.js'

/** 
 *  @param {Array} abtParamsMatchConfig abt解析配置
 *    @param {String} type 解析类型  ['emarsys', 'request']
 *    @param {RegExp} matchRule 解析规则
 *    @param {String | Function} url 当Type为request时处理url字段，请求接口地址
 *    @param {Object ｜ Function} params 接口请求参数
 *    @param {Boolean} notFaultTolerance 当前配置不需要走容错逻辑
 *    @param {Function} beforeSendHandler 数据发送前，执行回调
 *    @param {Number} timeout 冗错 超时时间, 一般设置 10000
 * {
 *      type: 'request',
 *      matchRule: /^is_pde=3/,
 *      url: `${ langPath }/product/recommend/getDetailRecommendProducts`,
 *      url: ({abtResult = {}} = {}) => {
 *          return url
 *      },
 *      params: ({abtResult = {}} = {}) => {
 *          return  params
 *      }
 *      params: {
 *          cat_id,
 *          goods_id,
 *          limit: 100,
 *          rule_id: /rule_id=([^&]+)/,
 *          abtInfo: ({abtResult = {}} = {}) => {
 *              return abtResult.expid
 *          }
 *      }
 *  }
 *  @param {Object} paramsMatchConfig 无abt时，解析配置
 *    @param {String} type 解析类型  ['emarsys', 'request']
 *    @param {String} url 当Type为request时处理url字段，请求接口地址
 *    @param {Object} params 接口请求参数
 *    @param {Boolean} notFaultTolerance 当前配置不需要走容错逻辑
 *    @param {Function} beforeSendHandler 数据发送前，执行回调
 * 
 *  @param {Object} faultTolerance 容错配置
 *    @param {String} mode 固定 faultTolerance
 *    @param {String} type 解析类型, 枚举['emarsys', 'request']
 *    @param {String} url 当Type为request时处理url字段，请求接口地址
 *    @param {Object} params 接口请求参数
 *    @param {Number} timeout 冗灾 超时时间, 一般设置 30000
 * 
 *  @param {Array} filterGoodsIdList 需要过滤的商品Id
*/
class ProductRecommend {
  constructor ({
    abtParamsMatchConfig = {}, 
    paramsMatchConfig = {},
    faultTolerance = null,
    filterGoodsIdList = []
  } = {}) {
    this.fetchData = new FetchData()
    this.abtParamsMatchConfig = abtParamsMatchConfig
    this.paramsMatchConfig = paramsMatchConfig
    this.faultTolerance = !faultTolerance 
      ? { type: 'none', params: {}, notFaultTolerance: true } 
      : faultTolerance
    this.filterGoodsIdList = filterGoodsIdList

    // 全场景添加none配置
    abtParamsMatchConfig.push({ type: 'none', matchRule: /^none$/, notFaultTolerance: true })

    // return data
    this.abtInfo = null
  }
  initAnalysis ({
    recommendType = 'swiper',
    layout = '', 
    listType = 'recommend-product-list', 
    scrollContainer = window,
    getExposeElements = null,
    scrollBottomMask = 0,
    exposeDaEventId,
  } = {}) {
    this.analysis = new analysis({
      recommendType,
      layout,
      listType, 
      scrollContainer,
      getExposeElements,
      scrollBottomMask,
      exposeDaEventId,
    })
  }

  async doRecommend ({ posKey = '', pageNum = 1, limit = 40, newPoskey, pageKey = '', itemConfig = {} } = {}) {
    /** 
     * 花木兰紧急需求，硬编码 begin
    */
    const excludeGoodsId = [1042795, 1042789, 1042788, 1042790, 1042797, 1042792, 1042796, 1042793, 1042791, 1042794]
    if (excludeGoodsId.includes(Number(window.SaPageInfo && SaPageInfo.page_param && SaPageInfo.page_param.goods_id))) {
      return {
        recommendInfo: {
          products: [],
          faultTolerant: 0,
          matchInfo: {}
        },
        abtInfo: ''
      }
    }
   
    let matchInfo = null
    let recommendInfo = null
    try {
      matchInfo = (posKey || newPoskey) ? await this._getMathInfoByAbtInfo({ posKey, newPoskey }) : this.paramsMatchConfig
      recommendInfo = matchInfo !== null ? await this._featDataByConfig({ matchInfo, pageKey, pageNum, limit, itemConfig }) : null

      // quantity less than ten，trigger fault tolerance
      let limitLength = this.faultTolerance && this.faultTolerance.triggerLimit || 10
      // pageNum
      const canIUseFaultReq = !matchInfo || (matchInfo && !matchInfo.notFaultTolerance) && (!recommendInfo || !recommendInfo.products || recommendInfo.products.length < limitLength)
      if (pageNum == 1 && canIUseFaultReq) {
        let faultReason = {
          abtId: '',
          reason: ''
        }
        let reasons = [recommendInfo && recommendInfo.reason || '']
        // 通过传入poskey去匹配请求配置 还是 手动传入请求配置
        if (!matchInfo && !this.abtInfo) {
          reasons.push('无实验')
        } else {
          // 1. 先判断实验
          const abtResult = this.abtInfo || this.paramsMatchConfig.sourceAbtInfo
          if (abtResult) {
            faultReason.abtId = abtResult.expid
            reasons.push(abtResult.param ? '实验参数非空' : '实验参数为空')
          }
          // 2. 在判断是否因为数量不足
          if (recommendInfo && recommendInfo.products && recommendInfo.products.length < limitLength) {
            reasons.push('推荐结果返回数量不足')
          }
        }

        faultReason.reason = reasons.filter(i => !!i).join(';')
        // 首次正常请求的时间
        const firstFetchTime = recommendInfo?.time || 0
        // 容错请求
        recommendInfo = await this._featDataByConfig({ matchInfo: this.faultTolerance, pageKey, faultReason, pageNum, limit, itemConfig })
        recommendInfo.fualtTime = recommendInfo.time // 容错请求的时间
        recommendInfo.time = firstFetchTime // 正常请求的时间
      }
      recommendInfo.products = this.filterList(recommendInfo.products || [], this.filterGoodsIdList)
    } catch(err) {
      console.error(err)
      const faultReason = {
        abtId: '',
        reason: '请求错误或者超时',
      }
      recommendInfo = await this._featDataByConfig({ matchInfo: this.faultTolerance, pageKey, faultReason, pageNum, itemConfig })
      // console.log('faultTolerance')
      // console.log('recommendInfo', recommendInfo)
      recommendInfo.faultTolerant = 1
      recommendInfo.products = this.filterList(recommendInfo.products || [], this.filterGoodsIdList)
    }

    // get extend info, 数据不互相依赖，并行执行
    let promises = []
    if (itemConfig.showRank) promises.push(middleware.getRank(recommendInfo.products))
    if (itemConfig.showMultiColor) promises.push(middleware.getMutliColor(recommendInfo.products))
    await Promise.all(promises)
    return {
      recommendInfo,
      abtInfo: this.abtInfo
    }
  }

  async _getMathInfoByAbtInfo ({ posKey, newPoskey } = {}) {
    let result = this.abtInfo = await this.fetchData.parseAbt({ poskey: posKey, newPoskey })
    if (result && /^emarsys/.test(result.param)) {
      const em = result.param.split('_')
      const emarsysType = em[1] || ''
      if (['related', 'cart'].includes(emarsysType)) {
        await emarsys.logicsCommand(emarsysType)
      }
    }
    // match rule
    let matchInfo = null
    let matchType = ['emarsys', 'request', 'none']

    let hasAbtResult = result instanceof Object && Object.prototype.hasOwnProperty.call(result, 'param')
    if (hasAbtResult) {
      for (let rule of this.abtParamsMatchConfig) {
        if (!matchType.includes(rule.type)) continue
        // 如果匹配规则是正则
        if (rule.matchRule instanceof RegExp && rule.matchRule.test(result.param)) {
          // match abt result
          matchInfo = {
            ...rule,
            matchResult: result
          }
          // Take the params from the abtInfo if the matchInfo.type is emarsys
          if (matchInfo.type === 'emarsys') matchInfo['params'] = result.param
          break
        }
        if (rule.matchRule instanceof Function && rule.matchRule({ abtResult: result || {} })) {
          matchInfo = {
            ...rule,
            matchResult: result
          }
          break
        }
      }
    }

    // get url by fun
    if (matchInfo && matchInfo.url instanceof Function) {
      matchInfo.url = matchInfo.url({ abtResult: result || {} })
    }

    // get params by fun
    if (matchInfo && matchInfo.params instanceof Function) {
      matchInfo.params = matchInfo.params({ abtResult: result || {} })
    }

    // get params field by reg or fun
    Object.keys(matchInfo && matchInfo.params || {}).forEach((item) => {
      let arg = matchInfo.params[item]
      // deal regexp
      if (result && result.param && typeof result.param == 'string' && arg instanceof RegExp) {
        let matchResult = result.param.match(arg)
        matchInfo.params[item] = matchResult && matchResult.length >= 2 ? matchResult[1] : ''
      }

      // deal fun
      if (arg instanceof Function) {
        let argValue = arg({ abtResult: result || {} })
        matchInfo.params[item] = argValue
      }
    })
    return matchInfo
  }

  async _featDataByConfig ({ matchInfo = {}, pageKey, faultReason, pageNum, limit, itemConfig } = {}) {
    let recommendInfo = {}
    matchInfo.beforeSendHandler && matchInfo.beforeSendHandler()
    const method = matchInfo.method || 'GET'
    const withAtomic = matchInfo.atomic

    switch(matchInfo.type) {
      case 'emarsys':
        recommendInfo = await this.fetchData.getEmarsysData({ type: method, logic: logicMap.get(matchInfo.params), timeout: matchInfo.timeout || 10000, limit: matchInfo.limit, itemConfig, pageKey })
        break
      case 'request': 
        {
          let data = matchInfo.params
          if (faultReason) {
            data['faultReason'] = JSON.stringify(faultReason)
          }
          recommendInfo = await this.fetchData.getOwnData({ withAtomic, type: method, url: matchInfo.url, data, timeout: matchInfo.timeout || 10000, pageNum, limit, itemConfig, pageKey })
        }
        break
      case 'none':
        recommendInfo.products = []
        break
      default:
        recommendInfo.products = []
    }
    if (matchInfo.mode === 'faultTolerance') recommendInfo.isFaultTolerant = 1
    return recommendInfo
  }

  updateFilterGoodsIdList(list) {
    if (list && list.length <= 0) return
    this.filterGoodsIdList = list
  }

  filterList (list, filterList) {
    if (filterList && filterList.length <= 0) return list
    return list.filter((item) => {
      return !filterList.includes(Number(item.goods_id))
    })
  }
}

export default ProductRecommend
