import { nextTick } from 'vue'
import { debounce } from '@shein/common-function'
import SProductItem from 'public/src/pages/components/productItem/ProductItem.vue'
import productItem from './recommendItem.vue'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import { daEventCenter } from 'public/src/services/eventCenter/index.js'
import { getRecAbtResult } from './utils'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail_v3/utils/productDetail.js'
import itemMixin from 'public/src/pages/components/productItem/mixin'
import getApolloConfig from '../utils/getApolloConfig'

daEventCenter.addSubscriber({ modulecode: '2-3' })
daEventCenter.addSubscriber({ modulecode: '1-7-1' })

export default {
  mixins: [itemMixin],
  data() {
    return {
      goodsMap: {},
      goodsItemInfo: null,
      productItemShowConfig: {},
      apolloConfig: null,
    }
  },
  components: {
    productItem,
    SProductItem,
  },
  computed: {
    recommendItems () {
      const query = this.goodsItemConfig.urlQuery || {}
      return this.recommendList && this.recommendList.map((item, index) => {
        item.index = index
        this.goodsItemInfo && this.goodsItemInfo.dealGoodsDetailUrl({ goodsItem: item, query: {
          mallCode: item.mall_code || '',
          ...query
        } })

        // 多颜色处理
        item.relatedColor && item.relatedColor.forEach((relateColorItem) => {
          relateColorItem.index = index
          this.goodsItemInfo.dealGoodsDetailUrl({ goodsItem: relateColorItem, query: {
            mallCode: relateColorItem.mall_code || '',
            ...query
          } })
        })
        this.goodsMap[item.goods_id] = item

        return item
      }) || []
    },
    newProductCard() {
      return this.productItemShowConfig.isNew
    }
  },
  async created() {
    const { 
      isQuickView, 
      showPromotion = true,
      isShowAddBagIcon,
      isAddToBagBtn,
      showLocalSeller = true,
      showNewFlashPrice = true,
      ...arg
    } = this.goodsItemConfig
    this.productItemShowConfig = {
      code: this.code,
      showSheinClubDiscountValue: true,
      showPromoLabel: true,
      showSellingPoint: false,
      disableMainimgJump: isQuickView,
      showPromotion,
      showAddBagAfterGoodsName: isShowAddBagIcon,
      hideOperationBtn: !isAddToBagBtn,
      showSeriesBrand: true,
      // 分人群专区闪购
      flashZonePromotion: true, // 默认展示，如果不展示需要在业务组件中自行设置为 false
      showLocalSeller, // 本地卖家角标
      showNewFlashPrice, // 新型闪购价格
      hideDiscountBadge: true, // 隐藏默认的折扣标签
      showDiscountLabelAfterPrice: true, // 折扣展示在价格右边
      hideRetailPrice: true, // 隐藏划线价
      showBeltLabel: true, // 腰带
      showEstimatedPriceReachThreshold: true, // 到手价
      ...arg,
    }
    const language = await i18n.loadByQueue('item') || {}
    this.goodsItemInfo = new GoodsItemInfo({ 
      listLanguage: language
    })

    
  },
  mounted() {
    if (this.apolloConfig) {
      getApolloConfig().then(apolloConfig => {
        this.apolloConfig = apolloConfig
        if (this.apolloConfig?.isSuggestedSalePrice && this.productItemShowConfig) {
          this.productItemShowConfig.showEstimatedPriceOnSale = false
          if (this.newProductCard) {
            this.productItemShowConfig.showSalesLabel = false
          } else {
            this.productItemShowConfig.standardPrice = true
          }
        }
      })
    }
    
    this._bindItemsEvent()
  },
  methods: {
    // 商品项的展示配置
    initItemAbt() {
      return new Promise(resolve => {
        // 如果已经请求过abt，直接返回不用再次请求
        if (this.productItemShowConfig.configReady) return resolve()

        getRecAbtResult.fetchAbt((config) => {
          const { showFollowLabel, showSellingPoint, showQuickShip: abtShowQuickShip, showBadge, showNewDiscountLabel, showGreySellingPoint, picSize } = config || {}
          const { showQuickShip: itemQuickShipShow } = this.productItemShowConfig
          const showQuickShip = typeof itemQuickShipShow === 'undefined' ? abtShowQuickShip : itemQuickShipShow
          // configReady 都已经取到最终值，控制内部计算底部角标时机
          this.productItemShowConfig = Object.assign({}, this.productItemShowConfig, { 
            showFollowLabel,
            showNewDiscountLabel, 
            showGreySellingPoint, 
            showBadge, 
            showQuickShip, 
            showSellingPoint, 
            imgDataExp: { ...(picSize ? { cutTo: picSize, max: 900 } : {}) },
            configReady: true
          })
          resolve()
        }, this.listType)
      })
    },
    async _handleProductsNewCard(products) {
      if (!this.apolloConfig) {
        const apolloConfig = await getApolloConfig()
        this.apolloConfig = apolloConfig
        if (this.apolloConfig.isSuggestedSalePrice && this.productItemShowConfig) {
          this.productItemShowConfig.showSalesLabel = false
        }
      }

      const { goodsItemConfig } = this
      if (!goodsItemConfig || !products?.length) return []
      const newProducts = await getDetailRecPreTreat({
        products,
        itemConfig: goodsItemConfig
      })
  
      return newProducts
    },
    async _handleProducts(goods, unFetchAtomic = false) {
      if (!this.apolloConfig) {
        const apolloConfig = await getApolloConfig()
        this.apolloConfig = apolloConfig
        if (this.apolloConfig.isSuggestedSalePrice && this.productItemShowConfig) {
          this.productItemShowConfig.standardPrice = true
        }
      }
      return new Promise(resolve => {
        if (!this.goodsItemInfo) return resolve(goods)
        const pageKey = this.config.sceneConfig?.pageKey || ''
        const subPageKey = 'fourFiveImgRec'
        // eval_cnt
        if (this.goodsItemInfo) {
          const extendAtomicFiles = this.productItemShowConfig?.extendAtomicFiles || []
          const requestMenuList = ['prices', 'topPick', 'plusSize', 'locateLabels', 'cornerPropertyUpsell', ...extendAtomicFiles]
          this.goodsItemInfo.getProductInfo(unFetchAtomic ? {
            pageKey: pageKey || subPageKey, // 即使没有原子请求也要传，因为色块在客户端请求，原子需要在调用getProductInfo的时候拿到pageKey，这样才能给色块的信息请求的时候用上
            subPageKey,
            goods,
            requestFields: {},
            itemConfig: this.productItemShowConfig,
          } : { 
            pageKey: pageKey || subPageKey,
            subPageKey,
            goods,
            requestFields: getAtomicRequestFileds(this.productItemShowConfig, requestMenuList, true),
            itemConfig: this.productItemShowConfig
          }).then(() => {
            // this.$refs && this.$refs.productItem && this.$refs.productItem.forEach((recommendItems) => {
            //   // 因价格等商品信息动态获取，故在数据获取后更新item的dom上的数据
            //   recommendItems.$refs && recommendItems.$refs.goodsItem && recommendItems.$refs.goodsItem.setElAttr()
            // })
            if (this?.$refs?.productItem?.length) {
              this.$refs.productItem.forEach(recommendItems => {
                recommendItems?.$refs?.goodsItem?.setElAttr?.()
              })
            }
            resolve(goods)
          })
        }
      })
    },
    _bindItemsEvent() {
      window.addEventListener('resize', debounce({
        func: () => {
        },
        wait: 200
      }))
    },
    // 如果展示多颜色需要对多颜色的信息做预处理
    _getScrollExposeDoms(data) {
      if (!data.length || !this.productItemShowConfig.showMultiColor) return
      let inScreenGoods = []
      data.forEach(({ content }) => {
        let goodsId = content.getAttribute('data-expose-id')
        goodsId = goodsId?.split?.('-')?.[1] || content.getAttribute('data-id') || ''
        if (!this.goodsMap[goodsId]) return
        inScreenGoods.push(this.goodsMap[goodsId])
      })
      // 色块请求原子的信息字段
      const colorAtomFields = getAtomicRequestFileds(this.productItemShowConfig, ['prices', 'topPick', 'plusSize'], true)

      if (inScreenGoods.length > 0) this.goodsItemInfo.getRelateColorProductInfo({ goods: inScreenGoods, colorAtomFields })
    }
  }
}
