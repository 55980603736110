<template>
  <div 
    class="product-item__holder"
    :class="{
      'product-item__hide-padding': hidePadding
    }"
  >
    <div
      class="product-item__holder-img"
      :style="placeholderBgImg"
    ></div>
    <div 
      v-if="!hideField.includes('name')"
      class="product-item__holder-name"
    ></div>
    <div
      v-if="!hideField.includes('price')"
      class="product-item__holder-price"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'CCCProductItemHolder',
  props: {
    brand: {
      type: String,
      default: 'shein',
    },
    hidePadding: {
      type: Boolean,
      default: false
    },
    hideField: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    placeholderBgImg() {
      const baseStyle = {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }
      if (this.brand !== 'shein') {
        baseStyle.backgroundImage = `url(https://imgholder.ltwebstatic.com/450x600.png?text=${this.brand}_v2)`
      }
      return baseStyle
    },
  },
}
</script>

<style lang="less" scoped>
.product-item {
  &__holder {
    padding-bottom: 20px;
  }
  &__hide-padding {
    padding-bottom: 0px;
  }
  &__holder-img {
    
    background-color: @sui_color_gray_weak1a;
    padding-bottom: 133.18%;
  }

  &__holder-name {
    background-color: @sui_color_gray_weak1a; 
    width: 80%;
    padding-bottom: 8%;
    margin-top: 5%;
  }

  &__holder-price {
    background-color: @sui_color_gray_weak1a;
    width: 30%;
    margin: 6px 0 5.78px 0;
    padding-bottom: 16px;
  }
}
</style>
